const VideoCard = ({ src, title, sizeMultiplier = 1 }) => {
    const width = 400 * sizeMultiplier;
    const height = 236 * sizeMultiplier;

    return (
        <div
            style={{
                border: "1px solid gray",
                width: `${width}px`,
                maxWidth: "100%",
            }}
        >
            <iframe
                style={{ maxWidth: "100%" }}
                width={width}
                height={height}
                src={src}
                title={title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen>
            </iframe>
        </div>
    );
};

export default VideoCard;
