import RegisterButton from "./RegisterButton";
import RegisterForm from "./RegisterForm";

const ReasonsToJoin2 = ({ showRegisterDialogParent }) => {
    return (<section className="bg-2">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12" style={{ padding: "10px", paddingTop: "55px" }}>

                    <div className='row advantage-box'>
                        <div className="label_text2">
                            Vreau să elimin confuzia și frica legate de AI.
                            <div className="label_text_small2">
                                Se spune că AI-ul va înlocui oamenii și că job-urile vor dispărea. Probabil că vezi AI-ul ca pe o amenințare și încerci să îl eviți. Dar cu cât îl eviți mai mult, cu atât ți se va părea mai înfricoșător. Cursul meu rupe acest cerc vicios. Îți ia doar 30 secunde ca să te înregistrezi, iar apoi mă ocup eu săptămână de săptămână să te ajut să înțelegi AI-ul și să vezi cum îl poți folosi în avantajul tău.
                            </div>
                        </div>
                    </div>

                    <div className='row advantage-box'>
                        <div className="label_text2">
                            Vreau să fac AI-ul ușor de înțeles.
                            <div className="label_text_small2">
                                Fără termeni complicați. O dată pe săptămână, trimit prin email lecții simple. Pornim de la zero. Folosim exemple clare și analogii. Sunt mereu aici dacă ai întrebări.
                            </div>
                        </div>
                    </div>

                    <div className='row advantage-box'>
                        <div className="label_text2">
                            “Nu AI-ul o să ne fure job-urile. Alți oameni care folosesc AI o să ni le fure.”
                            <div className="label_text_small2">
                                Roberto Saracco a spus asta.
                                Piața muncii de azi arată complet diferit față de cea de acum 20 de ani. Da, AI-ul va schimba multe lucruri și va face unele job-uri să dispară. Dar sunt convins că va crea și mai multe meserii pe care acum nu ni le putem imagina. Gândește-te doar la faptul că meseria de <em>social media manager</em> nu putea fi imaginată acum 20 de ani.
                            </div>
                        </div>
                    </div>




                    {/* <div className="mb-5 mt-3">
                        <RegisterButton id='reasons_to_join' fontSize="1.2em" onClick={showRegisterDialogParent}></RegisterButton>
                    </div> */}
                    {/* <RegisterForm isFormOpenInitial={false} id="reasons"></RegisterForm>

 */}



                </div>
            </div>
        </div>
    </section>
    );
}

export default ReasonsToJoin2;