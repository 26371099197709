const HowItWorks = ({ showRegisterDialogParent }) => {
    const handleLinkClick = (event) => {
        event.preventDefault();
        showRegisterDialogParent();
    };
    return (<>
        <a name="cum_functioneaza"></a>
        <section className="bg-2">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12" style={{ padding: "15px", paddingTop: "20px" }}>
                        <div className='row label_text' style={{
                            color: "#333", marginTop: "20px"
                        }}>
                            <div className="col" >
                                <h2>Cum funcționeazǎ?</h2>

                                O datǎ pe sǎptǎmânǎ îți trimit o lecție despre inteligența artificială prin e-mail.
                                Totul este gratuit.

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </section></>)
}

export default HowItWorks;
