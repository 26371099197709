import React, { useState } from 'react';
import RegisterButton from './RegisterButton';
import RegisterForm from './RegisterForm';



const TopIntro = ({ showRegisterDialogParent }) => {

    return (
        <>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-8 d-none d-lg-block">
                        <img src="assets/viorel3.png" className="img-fluid" alt="ViorelSpinu" />
                    </div>
                    <div className="col-12 d-lg-none">
                        <img src="assets/viorel_mobile_1.png" className="img-fluid" alt="ViorelSpinu" />
                    </div>

                    <div className="col-12 col-lg-4 text-header">
                        <div className="curs_desktop d-block mb-3">
                            Curs Gratuit AI
                        </div>
                        <div className="lectii_desktop d-block mb-3">
                            Lecții primite săptămânal prin email.
                        </div>

                        <div className="d-block mt-auto">
                            {/* <RegisterButton id="top_intro" onClick={showRegisterDialogParent} /> */}
                            <RegisterForm isFormOpenInitial={false} id="topintro"></RegisterForm>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopIntro;