import { useState } from "react";
import RegisterButton from "./RegisterButton.js";
import VideoCard from "./VideoCard.js";
import RegisterForm from "./RegisterForm.js";

const YoutubeEmbed = ({ showRegisterDialogParent }) => {
    const [showMoreVideos, setShowMoreVideos] = useState(false);

    const handleShowMore = () => {
        setShowMoreVideos(true);
    };

    return (
        <section className="bg-1">
            <div className="container">

                <div className="row justify-content-center">
                    <div
                        className="col-12"
                        style={{
                            padding: "0px",
                            paddingTop: "10px",
                            display: "flex",
                            gap: "20px",
                            flexWrap: "wrap",
                            justifyContent: showMoreVideos ? "flex-start" : "center",
                        }}
                    >
                        <VideoCard
                            src="https://www.youtube.com/embed/qNm3kH6DfOA?si=EwZ8eHozZVSGV5kv"
                            title="YouTube video player 1"
                            sizeMultiplier={!showMoreVideos ? 1.8 : 1}
                        />

                        {showMoreVideos && (
                            <>
                                <VideoCard
                                    src="https://www.youtube.com/embed/ScSCLcqNPNw?si=3aHnUrZ6OwN0Nt6z"
                                    title="YouTube video player 2"
                                />
                                <VideoCard
                                    src="https://www.youtube.com/embed/kt5xFf4dRvY?si=J_EF8FyA5NVvoEsi"
                                    title="YouTube video player 3"
                                />
                                <VideoCard
                                    src="https://www.youtube.com/embed/0aRf8g2wr34?si=nCDuprixKqNyOLL_"
                                    title="YouTube video player 4"
                                />
                                <VideoCard
                                    src="https://www.youtube.com/embed/t39dhOCSNSE?si=Oa6DdjPhfT0zb3Tf"
                                    title="YouTube video player 5"
                                />
                                <VideoCard
                                    src="https://www.youtube.com/embed/8T7ShJzWlKA?si=36eNO3PSeRSImQlc"
                                    title="YouTube video player 6"
                                />
                                <VideoCard
                                    src="https://www.youtube.com/embed/Sc5K3_yrtSk?si=_1-s-d9g0MydmyZS"
                                    title="YouTube video player 7"
                                />
                                <VideoCard
                                    src="https://www.youtube.com/embed/vfvoEwdVFUk?si=nefwviJpy7BRthz3"
                                    title="YouTube video player 8"
                                />
                                <VideoCard
                                    src="https://www.youtube.com/embed/E7bJ2IGe9o4?si=dPzpjiwpbhx17pnp"
                                    title="YouTube video player 9"
                                />
                            </>
                        )}
                    </div>
                    {!showMoreVideos && (
                        <div className="col-12 text-center mt-3">
                            <button onClick={handleShowMore} className="btn btn-primary">
                                Arată mai multe clipuri
                            </button>
                        </div>
                    )}
                    <div className="mb-2 mt-5 mb-5">
                        <RegisterForm isFormOpenInitial={true} id="qna"></RegisterForm>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default YoutubeEmbed;
