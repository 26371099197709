import React, { useState } from 'react';
import ProgressBar from "./ProgressBar";
import { register, notify } from '../api';
import './RegisterForm.css';
import { logData } from '../api';

const RegisterForm = ({ hideModal, isFormOpenInitial, id }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [termsChecked, setTermsChecked] = useState(false);
    const [gdprChecked, setGdprChecked] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [infoMessageClass, setInfoMessageClass] = useState('');
    const [isProgressStarted, setIsProgressStarted] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(isFormOpenInitial);

    const handleSubmit = async () => {
        try {
            if (!termsChecked || !gdprChecked) {
                setInfoMessageClass('warning');
                setInfoMessage('Pentru a continua este necesar să acceptați termenii și politica de confidențialitate.');
                return;
            }

            setInfoMessageClass('light');
            setInfoMessage('Stocǎm datele. Dureazǎ câteva zeci de secunde.');
            setIsProgressStarted(true);
            await register(name, email);
            setIsProgressStarted(false);
            setSubmitted(true);
            setInfoMessageClass('success');
            setInfoMessage('Am trimis un email de confirmare. Te rog sǎ dai click pe link-ul din email ca sǎ confirmi adresa de email');

            const referrer = document.referrer || "No referrer";
            const extraData = { referrer: referrer, id: id };
            logData("submitNew", extraData)
                .catch(() => {
                    console.error("Failed to log data");
                });


        } catch {
            setIsProgressStarted(false);
            setSubmitted(true);
            setInfoMessageClass('danger');
            setInfoMessage('Ceva nu a funcționat corect. Lasǎ-mi te rog un mesaj folosind butonul din dreapta sus.');
            notify("REGISTER_ERROR: " + name + " " + email + " " + window.location);
        }
    };


    const openForm = () => {
        const referrer = document.referrer || "No referrer";
        const extraData = { referrer: referrer, id: id };
        logData("openForm", extraData)
            .catch(() => {
                console.error("Failed to log data");
            });

        setIsFormOpen(true);

        setTimeout(() => {
            const formElement = document.querySelector('.register-form-container');
            if (formElement) {
                window.scrollTo({
                    top: formElement.offsetTop - (window.innerHeight * 0.2),
                    behavior: 'smooth'
                });
            }
        }, 100);

    };


    return (
        <>
            {!isFormOpen ? (
                <button className="btn btn-success btn-custom" onClick={openForm} style={{ fontSize: '1.4rem' }}>
                    ÎNSCRIE-TE LA CURS
                </button>
            ) : (
                <>
                    <div className='register-form-container'>
                        <h2 className='register-form-title'>Înregistrare la curs</h2>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            {infoMessage && (
                                <div
                                    id="infoModalMessage"
                                    style={{ fontSize: '90%' }}
                                    className={`mt-3 alert alert-${infoMessageClass}`}
                                >
                                    {infoMessage}
                                </div>
                            )}
                        </div>

                        <ProgressBar isProgressStarted={isProgressStarted} />

                        {!submitted && (
                            <div>
                                <div className='input-group'>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        placeholder="Nume şi prenume"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        disabled={submitted || isProgressStarted}
                                    />
                                </div>

                                <div className='input-group'>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="adresa de email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        disabled={submitted || isProgressStarted}
                                    />
                                </div>

                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="termsAndGdprPolicy"
                                        checked={termsChecked && gdprChecked}
                                        onChange={(e) => {
                                            setTermsChecked(e.target.checked);
                                            setGdprChecked(e.target.checked);
                                        }}
                                        disabled={submitted || isProgressStarted}
                                    />
                                    <label className="form-check-label checkbox-text" htmlFor="termsAndGdprPolicy">
                                        Sunt de acord cu <a target="_blank" href="/#/terms">termenii</a> și <a target="_blank" href="/#/confidentiality">politica de confidențialitate</a>
                                    </label>
                                </div>
                            </div>
                        )}

                        {!submitted && (
                            <div className="d-flex justify-content-start">
                                <button
                                    disabled={submitted || isProgressStarted}
                                    className="btn btn-primary m-2"
                                    onClick={handleSubmit}
                                >
                                    Trimite
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )
            }

        </>);
};

export default RegisterForm;
