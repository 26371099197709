import "./Header.css";
import React, { useEffect } from "react";
import { logData } from "../api.js";


const Header = ({ showAskAnythingDialog }) => {
    let startTime = new Date().getTime();

    const handleLinkClick = (event) => {
        event.preventDefault();
        showAskAnythingDialog();
    };

    const logReferrer = () => {
        const referrer = document.referrer || "No referrer";
        const extraData = { referrer: referrer };

        logData("visit", extraData)
            .catch(() => {

            });
    };

    const logTimeSpent = () => {
        const endTime = new Date().getTime();
        const timeSpent = Math.round((endTime - startTime) / 1000);
        logData("duration", timeSpent.toString())
            .catch(() => {

            });
    };

    useEffect(() => {
        logReferrer();

        window.addEventListener("beforeunload", logTimeSpent);

        return () => {
            window.removeEventListener("beforeunload", logTimeSpent);
        };
    }, []);


    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-white py-3" style={{ backgroundColor: "#FAFAFA !important" }}>
            <div className="container">
                <b><a className="navbar-brand" href="index.html">Viorel Spînu - Curs Gratuit AI</a></b>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="justify-content-end" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 small fw-bolder">
                        <li className="nav-item">
                            <a className="nav-link" onClick={handleLinkClick}
                                href="#"
                                id="askAnythingButton">
                                Întreabă-mă Orice
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Header;
