import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ImageLink = ({ src }) => (
    <img
        src={src}
        className="me-image2"
        alt="Carousel Slide"
        style={{ width: '100%', height: 'auto' }}
    />
);

const ConferenceSpeaker2 = ({ showRegisterDialogParent }) => {
    const images = [
        { href: "https://www.viorelspinu.com/2023/04/january-2023-march-2023-retrospective-ai.html", src: "assets/protv1.jpg?2" },
        { href: "https://www.viorelspinu.com/2023/04/january-2023-march-2023-retrospective-ai.html", src: "assets/absl.jpg?2" },
        { href: "https://www.viorelspinu.com/2023/04/january-2023-march-2023-retrospective-ai.html", src: "assets/protv4.jpg?2" },
        { href: "https://www.viorelspinu.com/2023/04/january-2023-march-2023-retrospective-ai.html", src: "assets/protv9.jpg?2" },
        { href: "https://www.viorelspinu.com/2023/04/january-2023-march-2023-retrospective-ai.html", src: "assets/cegeka.jpg?2" },
        { href: "https://www.viorelspinu.com/2023/04/january-2023-march-2023-retrospective-ai.html", src: "assets/protv10.jpg?2" },
        { href: "https://www.viorelspinu.com/2023/04/january-2023-march-2023-retrospective-ai.html", src: "assets/estee.jpg?2" },
        { href: "https://www.viorelspinu.com/2023/04/january-2023-march-2023-retrospective-ai.html", src: "assets/management1.jpg?2" },
        { href: "https://www.viorelspinu.com/2023/04/january-2023-march-2023-retrospective-ai.html", src: "assets/gandul.jpg?2" },
        { href: "https://www.viorelspinu.com/2023/04/january-2023-march-2023-retrospective-ai.html", src: "assets/bigdata.jpg?2" },
        { href: "https://www.viorelspinu.com/2023/04/january-2023-march-2023-retrospective-ai.html", src: "assets/marketing.jpg?2" },
        { href: "https://www.viorelspinu.com/2023/04/january-2023-march-2023-retrospective-ai.html", src: "assets/dwf.jpg?2" }
    ];

    const [itemsPerPage, setItemsPerPage] = useState(4);

    useEffect(() => {
        const updateItemsPerPage = () => {
            if (window.innerWidth < 576) {
                setItemsPerPage(2); // Small screens
            } else if (window.innerWidth >= 576 && window.innerWidth < 768) {
                setItemsPerPage(2); // Medium screens
            } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
                setItemsPerPage(3); // Intermediate screens
            } else {
                setItemsPerPage(4); // Large screens
            }
        };

        window.addEventListener('resize', updateItemsPerPage);
        updateItemsPerPage();

        return () => window.removeEventListener('resize', updateItemsPerPage);
    }, []);

    const groupedImages = images.reduce((acc, current, index) => {
        if (index % itemsPerPage === 0) acc.push([]);
        acc[acc.length - 1].push(current);
        return acc;
    }, []);

    const handleClickItem = (index) => {
        const href = images[index].href;
        if (href) {
            window.open(href, "_blank", "noopener noreferrer");
        }
    };

    return (
        <section className="bg-1">
            <div className="movie_container">
                <div className="row justify-content-center">
                    <div>
                        <h2 style={{ fontSize: "1.7em", color: '#EDEDED', marginTop: "10px", marginBottom: "15px", marginLeft: "10px" }}>
                            Cine sunt eu?
                        </h2>
                    </div>
                    <div style={{ marginTop: "0px", marginBottom: "10px" }}>
                        <Carousel
                            showThumbs={false}
                            showStatus={false}
                            infiniteLoop
                            useKeyboardArrows
                            autoPlay
                            interval={3000}
                            transitionTime={600}
                            dynamicHeight={true}
                            onClickItem={handleClickItem}
                        >
                            {groupedImages.map((group, index) => (
                                <div key={index} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    {group.map((item, idx) => (
                                        <div key={idx} style={{ flex: `1 1 ${100 / itemsPerPage}%`, padding: '0 0px' }}>
                                            <ImageLink src={item.src} />
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ConferenceSpeaker2;
