import React, { useState } from 'react';
import RegisterButton from './RegisterButton';
import { logData } from "../api.js";
import RegisterForm from './RegisterForm.js';

const CourseSummary = ({ showRegisterDialogParent }) => {
    const [showDetails, setShowDetails] = useState(false);

    const toggleDetails = () => {
        setShowDetails(!showDetails);
        const referrer = document.referrer || "No referrer";
        const extraData = { referrer: referrer, state: !showDetails };
        logData("openSummary", extraData)
            .catch(() => {

            });
    };

    return (
        <section className="bg-2">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12" style={{ padding: "15px", paddingTop: "0px" }}>
                        <div className='row label_text' style={{ color: "#333", marginTop: "0px" }}>
                            {/* <div className='mb-5'>
                                <RegisterForm id="summary" isFormOpenInitial={true}></RegisterForm>
                            </div> */}
                            <div className="col">
                                <h2>Ce înveți la curs?</h2>
                                <div className="label_text_summary_large" style={{ marginBottom: "5px" }}>
                                    {/* În acest curs vei descoperi pas cu pas ce este AI și <b>cum funcționează un Large Language Model</b> (ChatGPT este un LLM), într-un mod clar și ușor de înțeles. Vei învăța <b>cum să creezi prompturi eficiente</b> și să folosești tehnici avansate de prompting pentru a obține cele mai bune rezultate. Vom vedea <b>cum AI-ul ne poate ajuta să generăm atât texte, cât și imagini</b>. Vom scrie împreună o poveste pentru copii, dar și <b>răspunsuri la cererile de suport venite de la clienții tăi</b>. Vom explora cum aceste abilități de a folosi AI-ul pot fi <b>aplicate practic în interacțiunile din business</b>. Totul într-un mod intuitiv, cu exemple și experimente practice. */}
                                    {/* <ul>
                                        <li>Descoperi pas cu pas ce este AI și cum funcționează un Large Language Model (LLM), precum ChatGPT.</li>
                                        <li>Înveți cum să creezi prompturi eficiente și să folosești tehnici avansate de prompting (Few Shot Prompting, Chain of Thought, Self Consistency sau ReAct) pentru rezultate optime.</li>
                                        <li>Explorezi cum AI-ul poate genera orice tip de conținut: text, imagini, podcast-uri, muzică.</li>
                                        <li>Scriem împreună o poveste pentru copii și o ilustrăm folosind AI-ul.</li>
                                        <li> Învățăm cum să cerem AI-ului să răspundă cererilor de suport primite de la clienții tăi.</li>
                                        <li>Aflăm cum aceste abilități pot fi aplicate în interacțiunile de business.</li>
                                        <li>Totul este prezentat într-un mod intuitiv, cu exemple și experimente practice.</li>
                                    </ul> */}
                                    {/* Descoperi cum să folosești inteligența artificială pentru a crea diverse tipuri de conținut și a automatiza interacțiuni și procese, aplicând tehnici avansate de prompting într-un mod practic și intuitiv. */}
                                    Vei învăța cum să utilizezi inteligența artificială pentru a-ți simplifica munca și pentru a automatiza diverse sarcini creative și operaționale. Cursul îți arată cum să folosești AI-ul într-un mod practic și aplicabil. Este o combinație de explicații simple și exemple concrete care te ajută să înțelegi și să aplici ceea ce înveți, fără teorie complicată.
                                </div>

                                <button className="btn btn-secondary" style={{ fontSize: "0.85em", marginBottom: "15px" }} onClick={toggleDetails}>
                                    {showDetails ? 'Ascunde cuprinsul detaliat' : 'Vezi cuprinsul detaliat'}
                                </button>

                                {showDetails && (
                                    <div className="label_text_summary_large" style={{ backgroundColor: "#f7f7f7", padding: "20px", marginTop: "20px", borderRadius: "10px", lineHeight: "1.6" }}>
                                        <div className="label_text_summary">
                                            <strong>Lecția 1: Introducere în AI:</strong> În prima lecție, vei învăța ce este AI-ul și cum funcționează ChatGPT. Vom explora cum folosești deja AI în viața de zi cu zi și cum îl poți integra mai bine în activitățile tale. De asemenea, vei înțelege cum funcționează un model de limbaj mare (LLM) și cum poate fi folosit pentru sarcini zilnice.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 2: Ce NU este ChatGPT:</strong> În această lecție vom clarifica unele concepții greșite despre ChatGPT. Discutăm despre ce nu este ChatGPT. Vom înțelege procesele interne care transformă o întrebare într-un răspuns inteligent și coerent.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 3: Cum învață inteligența artificială:</strong> Vom descoperi cum funcționează procesul de învățare al unui sistem cu inteligență artificială (cum este și ChatGPT). Vei înțelege de ce nu este vorba de o simplă căutare într-o bază de date, ci de un proces complex de generare a răspunsurilor pe baza textelor antrenate.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 4: Puterea prompturilor:</strong> Vom învăța cum să formulăm prompturi pentru a obține cele mai bune rezultate de la AI. Prompturile bine formulate asigură că AI-ul va livra răspunsuri cât mai relevante și adaptate nevoilor noastre.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 5: Tehnici avansate de prompting:</strong> În această lecție vom explora tehnici avansate precum "Chain of Thought" și "Act-As" care permit AI-ului să abordeze sarcini complexe și să genereze răspunsuri mai detaliate.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 6: Crearea de prompturi eficiente:</strong> Vom explora ce înseamnă un prompt clar, concis și relevant, cu exemple practice și instrucțiuni detaliate. Vom învăța să rafinăm interacțiunile cu AI-ul, similar cu modul în care purtăm o discuție cu un partener uman.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 7: Few-shot prompting și self-consistency:</strong> Aceste tehnici îți permit să oferi AI-ului exemple concrete pentru a obține răspunsuri mai precise și să folosești verificarea iterativă pentru a îmbunătăți calitatea rezultatelor.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 8: Chain of Thought și Prompt Chaining:</strong> Această lecție îți prezintă tehnici esențiale pentru descompunerea problemelor complexe în pași mai simpli și adăugarea de informații noi pentru a obține răspunsuri mai precise.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 9: Aplicații practice: sumarizare și traducere:</strong> În această lecție vei aplica practicile învățate pentru sumarizarea textelor complexe și traducerea acestora, adaptând AI-ul la contextul cultural și emoțional specific.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 10: Tree of Thoughts, RAG și ReAct:</strong> Lecția introduce trei tehnici avansate care sunt esențiale pentru sarcini complexe și interacțiuni cu sisteme externe.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 11: Generarea de imagini cu AI:</strong> Ne concentrăm pe cum poți folosi AI-ul pentru a transforma textul în imagini captivante folosind modele precum DALL-E, Midjourney și Stable Diffusion.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 12: Utilizarea practică a platformelor AI:</strong> Această lecție ne va ghida prin utilizarea practică a platformelor AI pentru a genera imagini și a le personaliza în funcție de nevoile tale.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 13: Explorarea altor modele AI:</strong> Vom explora alte platforme și modele AI disponibile pentru generarea de imagini și vom învăța cum să experimentezi cu diverse abordări.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 14: Crearea unei povești pentru copii:</strong> Vom aplica toate cunoștințele acumulate pentru a crea o poveste, trecând prin pași de brainstorming, dezvoltarea personajelor și crearea intrigii.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 15: Ilustrarea poveștii:</strong> Vei învăța cum să ilustrezi povestea creată anterior, testând diverse stiluri de ilustrație și asigurând consistența vizuală între scene.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 16: Utilizarea AI în suportul pentru clienți:</strong> Vom explora cum să folosești AI-ul pentru a genera răspunsuri rapide și eficiente pentru cererile de suport venite de la clienți.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 17:</strong> Lecția 17 este încă în lucru.
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CourseSummary;
